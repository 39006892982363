<template>
  <!-- 已退款 -->
  <div id="enterpriseServiceInquiry">
    <div class="result-panel">
      <CSTable>
        <template v-slot:thead>
          <tr>
            <th class="text-center">退款时间</th>
            <th class="text-center">项目名称</th>
            <th class="text-center">服务提供方</th>
            <th class="text-center">抽成比例</th>
            <th class="text-center">用户信息</th>
            <th class="text-center">服务日期</th>
            <th class="text-center">应付金额</th>
            <th class="text-center">已付金额</th>
            <th class="text-center">已退金额</th>
            <th class="text-center">退款原因</th>
            <th class="text-center">退款人</th>
            <th class="text-center">支付渠道</th>
            <th class="text-center">附件</th>
            <th class="text-center">备注</th>
            <th class="text-center">用户评价</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="isLoading">
            <tr v-for="(log, index) in list" :key="index">
              <td class="text-center date-td">
                {{ log.refundTime || "-" }}
              </td>
              <td class="text-center">
                {{ log.itemName || "-" }}
              </td>
              <template>
                <td class="text-center">
                  <p>{{ log.providerName || "-" }}</p>
                  <p v-if="log.providerContact">
                    {{ log.providerContact }}
                  </p>
                  <p>
                    {{ log.providerPhone }}
                  </p>
                </td>
              </template>
              <td class="text-center">
                {{ log.commissionScale ? log.commissionScale + "%" : "-" }}
              </td>
              <td class="text-center">
                <p v-if="log.userName" style="margin-bottom: 0">
                  {{ log.userName }}
                  【{{ log.userPhone }}】
                </p>
                <p v-if="log.userCompany" style="margin-bottom: 0">
                  {{ log.userCompany }}
                </p>
                <p v-if="log.userCompanyAddr" style="margin-bottom: 0">
                  {{ log.userCompanyAddr }}
                </p>
              </td>
              <td class="text-center">
                {{ log.serviceDate || "-" }}
              </td>
              <td class="text-center">
                {{ log.payableMoney }}
              </td>
              <td class="text-center">
                {{ log.paymentMoney || "-" }}
              </td>
              <td class="text-center">
                {{ log.refundMoney || "-" }}
              </td>
              <td class="text-center">
                <span class="allow-click" @click="refundReason(log)">查看</span>
              </td>
              <td class="text-center">
                {{ log.refundName || "-" }} ({{ log.refundDepartment }})
              </td>

              <td class="text-center">
                <template v-if="log.payChannel != null">
                  <template v-if="[0, 1, 3].includes(log.payChannel)">
                    <p>{{ PAY_CHANNEL_MAPPING[log.payChannel] || "-" }}</p>
                    <p>{{ log.paymentOrderNo }}</p>
                  </template>
                  <template v-if="log.payChannel == 2">
                    <span
                        v-if="log.voucher && log.voucher !== ''"
                        class="allow-click"
                        @click="lookImage(log.voucher)"
                    >现金凭证</span
                    >
                    <template v-else>-</template>
                  </template>
                </template>
                <template v-else>-</template>
              </td>

              <td class="text-center">
                <span
                    v-if="log.annex && JSON.parse(log.annex).length > 0"
                    class="allow-click"
                    @click="lookContractAttachments(log.annex, log.id)"
                >
                  查看
                </span>
                <template v-else>-</template>
              </td>
              <td class="text-center">
                <span
                    v-if="log.comment"
                    class="allow-click"
                    @click="lookComment(log)"
                >查看</span
                >
                <template v-else>-</template>
              </td>
              <td class="text-center">
                <span
                    v-if="log.score"
                    class="allow-click"
                    @click="lookEvaluate(log)"
                >查看</span
                >
                <template v-else>-</template>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td :colspan="12">
              <div style="text-align: center">暂无数据</div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination/>
    </div>
    <!-- 点击备注的弹出框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="dialogVisible"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="备注"
        dialogWidth="480px"
        @onClose="dialogVisible = false"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        {{ item.comment }}
      </div>
    </CSDialog>

    <!-- 点击评价的弹出框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="evaluates"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="评价"
        dialogWidth="480px"
        @onClose="evaluates = false"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        <label style="margin-right: 30px">评价</label>
        <span>{{ item.score }} {{ item.evaluate }}</span>
      </div>
    </CSDialog>

    <!-- 点击退款原因的弹出框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="noSigning"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="退款原因"
        dialogWidth="480px"
        @onClose="noSigning = false"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <div>
          <label style="width: 120px; margin-right: 30px; text-align: right"
          >退款人</label
          ><i>{{ item.refundName }}</i>
        </div>
        <div>
          <label style="width: 120px; margin-right: 30px; text-align: right"
          >已付金额</label
          ><i>{{ item.paymentMoney }} 元</i>
        </div>
        <div>
          <label style="width: 120px; margin-right: 30px; text-align: right"
          >已退金额</label
          ><i>{{ item.refundMoney }} 元</i>
        </div>
        <div>
          <label style="width: 120px; margin-right: 30px; text-align: right"
          >退款原因</label
          ><i>{{ item.refundCause }}</i>
        </div>
      </div>
    </CSDialog>
    <ViewModal/>
    <ViewImageModal
        componentName="ViewImageModal"
        name="viewImageModal"
    ></ViewImageModal>
  </div>
</template>

<script>
import ViewImageModal from "@/components/ViewImageModal.vue";
import CSDialog from "@/components/common/CSDialog";
import {deleteAnnexUrl, getadminQueryOrderUrl} from "@/requestUrl";
import ViewModal from "@/components/ViewModal";
import Pagination from "@/components/Pagination";
import {PAY_CHANNEL, PAY_CHANNEL_MAPPING} from "@/constant";
import CSTable from "@/components/common/CSTable";

const MEETING_ROOM_TYPE = {
  PUBLIC: 2,
  PRIVATE: 1,
};

export default {
  components: {
    isLoading: true,
    CSTable,
    ViewImageModal,
    ViewModal,
    CSDialog,
    Pagination,
  },
  name: "enterpriseServiceInquiry",
  data() {
    return {
      PAY_CHANNEL,
      PAY_CHANNEL_MAPPING,
      // 订单返回来的数据
      list: [],
      // 控制评价弹出框的显示与隐藏
      evaluates: false,
      // 控制备注弹出框的显示与隐藏
      dialogVisible: false,
      // 控制退款原因弹出框的显示与隐藏
      noSigning: false,
      item: "",
      MEETING_ROOM_TYPE,
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    this.queryItem();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.queryItem(pageNo);
    });
  },
  methods: {
    // 查询询价中的数据
    queryItem(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly
          .post(getadminQueryOrderUrl, {
            regionId: this.$vc.getCurrentRegion().communityId, //区域id
            state: 4, //1.询价中2.已签约3.不签约4.已退款
            pageNo,
            pageSize,
          })
          .then((res) => {
            if (res.data.datas.length == 0) {
              this.isLoading = false;
            }

            this.list = res.data.datas;
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
          });
    },
    // 退款原因弹框事件
    refundReason(log) {
      this.noSigning = true;
      this.item = log;
    },
    // 备注事件弹框
    lookComment(log) {
      this.dialogVisible = true;
      this.item = log;
    },
    // 评价事件弹框
    lookEvaluate(log) {
      this.evaluates = true;
      this.item = log;
    },
    // 查看凭证
    lookImage(photos) {
      this.$vc.emit(
          this.$route.path,
          "viewImageModal",
          "view",
          JSON.parse(photos)
      );
    },

    /**
     * 查看合同附件
     * @param {Array} attachments 附件地址
     * @param {Number} id 记录id
     *  */
    lookContractAttachments(attachments, id) {
      let trStr = "";
      JSON.parse(attachments).forEach((val) => {
        trStr += `<tr>
                        <td class="text-center">${
            val.split("/")[val.split("/").length - 1]
        }</td>
                        <td class="text-center">
                            ${
            ["png", "jpg", "jpeg", "gif", "webp"].includes(
                val.split(".")[val.split(".").length - 1]
            )
                ? `<button class="btn btn-link btn-look-attachment" data-src="${val}">查看</button>`
                : ""
        }
                            <button class="btn btn-link btn-download" data-src="${val}">下载</button>
                            <button class="btn btn-link btn-delete" data-id="${id}" data-src="${val}">删除</button>
                        </td>
                    </tr>`;
      });
      this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
        title: "查看",
        content: `
                        <table class="footable table table-stripped toggle-arrow-tiny modal-inner-table">
                            <thead>
                                <tr><th class="text-center">名称</th><th class="text-center">操作</th></tr>
                            </thead>
                            <tbody>
                                ${trStr}
                            </tbody>
                        </table> `,
      });
      this.$nextTick(() => {
        const downloadBtns = document.querySelectorAll(".btn-download"),
            deleteBtns = document.querySelectorAll(".btn-delete"),
            lookBtns = document.querySelectorAll(".btn-look-attachment");
        downloadBtns.forEach((el) => {
          el.addEventListener("click", (e) => {
            this.downloadFile(e.target.dataset.src);
          });
        });
        deleteBtns.forEach((el) => {
          el.addEventListener("click", (e) => {
            const {src, id} = e.target.dataset;
            this.openDeleteFile(id, src).then((res) => {
              if (res.code === 0) {
                this.$vc.toast("删除成功");
                this.$CSDialog.instance.closeDialog();
                el.parentNode.parentNode.parentNode.removeChild(
                    el.parentNode.parentNode
                );
                this.queryItem();
              }
            });
          });
        });
        lookBtns.forEach((el) => {
          el.addEventListener("click", (e) => {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
              photos: e.target.dataset.src,
            });
          });
        });
      });
    },
    openDeleteFile(id, annex) {
      return new Promise((resolve) => {
        this.$CSDialog.confirm({
          title: "提示",
          message: "确定删除吗？",
          onConfirm: async () => {
            resolve(await this.deleteFile(id, annex));
          },
        });
      });
    },
    deleteFile(id, annex) {
      return this.$fly.post(deleteAnnexUrl, {
        regionId: this.$vc.getCurrentRegion().code,
        annex,
        id,
      });
    },
    /**
     * 下载文件（仅限阿里oss的文件）
     * @param {String} fileSrc 文件的资源地址
     *  */
    downloadFile(fileSrc) {
      window.open(
          fileSrc.replace(
              "https://alioss.techops.cn/",
              "https://cosmos-img.oss-cn-beijing.aliyuncs.com/"
          )
      );
    },
  },
};
</script>

<style scoped>
#enterpriseServiceInquiry .el-dialog__header {
  padding: 0;
  height: 0;
}

#enterpriseServiceInquiry .el-dialog__body {
  padding: 20px 0 20px 30px;
}

#enterpriseServiceInquiry .el-dialog__footer {
  padding-top: 20px;
  text-align: center;
  border-top: 1px solid #999;
}

.signBox > el-dialog__footer {
  border-top: 1px solid #999;
}

.el-dialog .cancel {
  width: 80px;
  height: 40px;
  background: #ff9f00;
  color: #fff;
  font-size: 20px;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  padding: inherit;
  margin-right: 30px;
}

.el-dialog .confirm {
  width: 80px;
  height: 40px;
  color: #fff;
  background: #1ab394;
  font-size: 20px;
  padding: inherit;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
}

#enterpriseServiceInquiry .el-card__body {
  padding: 3px 0 0px 0;
}
</style>

<style scoped>
.text-center {
  padding-bottom: 10px;
  vertical-align: middle;
}

.box_padding {
  margin-bottom: 30px;
}

i {
  font-style: normal;
}

input[type="radio"] {
  width: 23px;
  height: 16px;
  cursor: pointer;
  position: relative;
  outline: none;
}

.yellow_ {
  width: 80px;
  height: 40px;
  background: #ff9f00;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  color: #fff;
  border: none;
}

.el-dialog div {
  margin: 10px 0;
}
</style>
